import styled from "styled-components";
import { BANNER_PARAGRAPH, BANNER_TITLE } from "lib/testimony-constants";

const StyledBanner = styled.div`
  height: 319px;
  background-image: linear-gradient(to top, #5f72bd 0%, #9b23ea 100%);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 0px;
  font-family: ${({ theme }) => theme.fonts.roboto}, sans-serif;
  .title {
    color: ${({ theme }) => theme.colors.light};
    font-size: ${({ theme }) => theme.fontSizes.title};
    font-family: ${({ theme }) => theme.fonts.roboto}, sans-serif;
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 760px) {
      font-size: ${({ theme }) => theme.fontSizes.large};
    }
  }
  .subtitle {
    color: ${({ theme }) => theme.colors.light};
    font-size: ${({ theme }) => theme.fontSizes.large};
    @media (max-width: 760px) {
      font-size: ${({ theme }) => theme.fontSizes.small};
      padding: 10px 16px;
    }
  }
`;

const Banner = () => (
  <StyledBanner>
    <div className="title">{BANNER_TITLE}</div>
    <div className="subtitle">{BANNER_PARAGRAPH}</div>
  </StyledBanner>
);

export default Banner;
